@import "../../sass/colors";
@import "../../sass/layout";

.service-item {
  overflow: hidden;

  padding: 1em;

  border-radius: 1em;
  background-color: $color-white;

  grid-column: span 4;

  &.dark {
    background-color: $color-grey-background;
  }

  @include md-screen {
    margin: 1em;
    grid-column: span 2;
  }

  @include lg-screen {
    grid-column: span 1;
  }

  h3 {
    margin: 0;

    text-align: center;
    text-transform: capitalize;
  }

  img {
    margin-bottom: 0.5em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }
}
