@import "../../sass/colors";
@import "../../sass/layout";

.works-item {
  position: relative;

  padding: 1em;

  border-radius: 1em;
  background-color: $color-white;

  grid-column: span 4;

  @include md-screen {
    grid-column: span 2;
    margin: 1em;
  }

  &.dark {
    background-color: $color-grey-background;
  }

  .coming-soon {
    position: absolute;
    z-index: 100;

    top: -3px;
    left: -5px;
    width: 4em;

    @include md-screen {
      top: -8px;
      left: -10px;

      width: 8em;
    }

    @include lg-screen {
      top: -9px;
      left: -11px;

      width: 10em;
    }
  }

  .image-container {
    position: relative;

    overflow: hidden;

    height: 12em;

    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    margin-bottom: 1em;
  }

  h3 {
    margin: 0;

    text-align: center;
  }
}
