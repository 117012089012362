@import "./sass/typography";
@import "./sass/layout";
@import "./sass/colors";

body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  color: $color-text;
}

a,
a:visited {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
