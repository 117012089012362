$color-black: #000;
$color-grey-dark: #444;
$color-grey: #888;
$color-grey-light: #ccc;
$color-grey-background: lighten($color-grey-light, 17%);
$color-white: #fff;

$color-red: #ad0023;
$color-green: #00ad2e;

$color-primary: #bc343e;
$color-secondary: #ff4f5a;

$color-text: #1a2e35;
$color-text-secondary: $color-grey;
